import * as Sentry from '@sentry/gatsby';

const isProduction = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn: process.env.SENTRY_DSN,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: isProduction ? 0.25 : 1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  tracesSampleRate: isProduction ? 0.25 : 1,
  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      tracePropagationTargets: isProduction ? ['omorpho.com'] : ['localhost', 'omorpho-staging.netlify.app'],
    }),
  ],
});
